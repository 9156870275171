import axiosClient from '../axios/AxiosClient';
import { getCookie } from '../utils/cookies';
import { rfqEndpoints } from './rfqs.endpoints';

export const getAgencyRFQs = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(rfqEndpoints.getAgencyRFQs(agencyId));
  return results?.data;
};

export const createRFQOffers = async (rfqId, RFQs) => {
  const results = await axiosClient.post(rfqEndpoints.createOffer(rfqId), RFQs);
  return results?.data;
};

export const declineRFQ = async (rfqId) => {
  const results = await axiosClient.get(rfqEndpoints.rejectRFQ(rfqId));
  return results?.data;
};
