import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  // OutlinedInput,
  // InputAdornment,
} from '@mui/material';
// components
import { useMutation, useQuery } from '@tanstack/react-query';
import { Toast } from '../../axios/Toast';
import { getCookie } from '../../utils/cookies';
import ModalDialog from '../../components/modal/ModalDialog';
import TripDetails from './tripDetails/TripDetails';
import { deleteTrip, getAgencyTrips, postToMarketplaceOnPrem, postToMarketplaceSaas } from '../../api/trips.api';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import SliderModal from '../../components/slider-modal/SliderModal';
import NewTrip from './newTrip/NewTrip';
import pallete from '../../theme/palette';
import CreateTripActivity from './createActivity/CreateTripActivity';

const TABLE_HEADS = [
  { label: 'DESTINATION' },
  { label: 'TITLE' },
  { label: 'PACKAGES' },
  { label: 'CATEGORIES' },
  { label: 'STATUS' },
  { label: 'ACTIONS' },
];

export default function AgencyTrips() {
  const { hasMarketplace } = getCookie('UserInfo');

  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [tripsList, setTripsList] = useState([]);
  const [newTripFormOpen, setNewTripFormOpen] = useState(false);
  const [tripDetailsOpen, setTripDetailsOpen] = useState(false);
  const [tripActivityOpen, setTripActivityOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [deleteDialogOpne, setDeleteDialogOpen] = useState(false);
  const [postDialogOpen, setPostDIalogOpen] = useState(false);
  const [postedDialogOpen, setPostedDialogOpen] = useState(false);

  const { refetch } = useQuery(['getAgencyTrips'], getAgencyTrips, {
    onSuccess: (data) => {
      setTripsList(data.data || []);
      setFilteredTrips(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tripsList.length) : 0;

  const { mutate: deleteTripMutation } = useMutation(['deleteTrip'], () => deleteTrip(selectedTrip.id), {
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
      } else {
        Toast.error(response.message);
      }
      refetch();
    },
    onError: () => {
      Toast.error('An error occurred');
    },
  });

  const { mutate: postMarketPlaceSaasMutation } = useMutation(
    ['postToMarketplace'],
    () => postToMarketplaceSaas(selectedTrip.id),
    {
      onSuccess: (response) => {
        if (response.success) {
          Toast.success(response.message);
          refetch();
        } else {
          Toast.error(response.message);
        }
      },
      onError: () => {
        Toast.error('An error occurred');
      },
    }
  );

  const { mutate: postMarketPlaceOnpremMutation } = useMutation(
    ['postToMarketplace'],
    () => postToMarketplaceOnPrem(selectedTrip),
    {
      onSuccess: (response) => {
        if (response.success) {
          Toast.success(response.message);
          refetch();
        } else {
          Toast.error(response.message);
        }
      },
      onError: () => {
        Toast.error('An error occurred');
      },
    }
  );

  const handlePostToMarketplace = () => {
    Toast.loading('Please wait');
    if (process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL-SAAS') {
      postMarketPlaceSaasMutation();
    } else {
      postMarketPlaceOnpremMutation();
    }
  };

  return (
    <>
      <Page title="Packages">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Packages
            </Typography>
            <Button
              variant="contained"
              onClick={() => setNewTripFormOpen(true)}
              startIcon={<Iconify icon="eva:plus-outline" />}
            >
              New Package
            </Button>
          </Stack>

          <Card>
            {/* <OutlinedInput
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              sx={{
                margin: 2,
              }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTrips.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        destinationName,
                        tripTitle,
                        tripCategories,
                        tripCostPackages,
                        tripStatus,
                        featureImage,
                        inMarketplace,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={destinationName} src={featureImage} />
                              <Typography variant="subtitle2" noWrap>
                                {destinationName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{tripTitle}</TableCell>
                          <TableCell align="left">{tripCostPackages.length || 0}</TableCell>
                          <TableCell align="left">{tripCategories.join(', ')}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(tripStatus === 'INACTIVE' && 'error') || 'success'}>
                              {sentenceCase(tripStatus)}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Iconify
                              onClick={() => {
                                setSelectedTrip(row);
                                setTripDetailsOpen(true);
                              }}
                              icon="eva:info-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.info.main,
                                marginRight: 2,
                              }}
                            />

                            {hasMarketplace && !inMarketplace && (
                              <Iconify
                                onClick={() => {
                                  setSelectedTrip(row);
                                  setPostDIalogOpen(true);
                                }}
                                icon="mdi:marketplace"
                                width={30}
                                height={30}
                                sx={{
                                  color: pallete.primary.main,
                                  marginRight: 2,
                                }}
                                title="Post to marketplace"
                              />
                            )}

                            {hasMarketplace && inMarketplace && (
                              <Iconify
                                onClick={() => {
                                  setSelectedTrip(row);
                                  setPostedDialogOpen(true);
                                }}
                                icon="mdi:marketplace"
                                width={30}
                                height={30}
                                sx={{
                                  color: '#8db600',
                                  marginRight: 2,
                                }}
                                title="Posted to marketplace"
                              />
                            )}
                            <Iconify
                              onClick={() => {
                                setSelectedTrip(row);
                                setTripActivityOpen(true);
                              }}
                              icon="eva:activity-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: '#800080',
                                marginRight: 2,
                              }}
                              title="Start Activity"
                            />

                            <Iconify
                              onClick={() => {
                                setSelectedTrip(row);
                                setDeleteDialogOpen(true);
                              }}
                              icon="eva:trash-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.error.main,
                                marginRight: 2,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tripsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <SliderModal openState={newTripFormOpen} setOpenState={setNewTripFormOpen}>
        <NewTrip refetch={refetch} setOpenState={setNewTripFormOpen} />
      </SliderModal>

      <SliderModal openState={tripDetailsOpen} setOpenState={setTripDetailsOpen}>
        <TripDetails refetch={refetch} setOpenState={setTripDetailsOpen} trip={selectedTrip} />
      </SliderModal>

      <SliderModal openState={tripActivityOpen} setOpenState={setTripActivityOpen}>
        <CreateTripActivity setOpenState={setTripActivityOpen} trip={selectedTrip} />
      </SliderModal>

      <ModalDialog
        isOpen={deleteDialogOpne}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          Toast.loading('Please wait');
          deleteTripMutation();
        }}
        cancelText={'Cancel'}
        confirmText={'Delete'}
        dialogContent={`This trip will be deleted: ${selectedTrip.tripTitle}`}
        dialogTitle={'Delete trip?'}
      />

      <ModalDialog
        isOpen={postDialogOpen}
        onCancel={() => setPostDIalogOpen(false)}
        onConfirm={() => {
          handlePostToMarketplace();
        }}
        cancelText={'Cancel'}
        confirmText={'Post'}
        dialogContent={`This trip will be posted to the marketplace: ${selectedTrip.tripTitle}`}
        dialogTitle={'Post trip?'}
      />

      <ModalDialog
        isOpen={postedDialogOpen}
        onCancel={() => setPostedDialogOpen(false)}
        onConfirm={() => {}}
        cancelText={'Cancel'}
        confirmText={'Remove'}
        dialogContent={`This trip is already posted to the marketplace: ${selectedTrip.tripTitle}`}
        dialogTitle={'Remove from marketplace'}
      />
    </>
  );
}
