import { differenceInCalendarDays, format, formatDistanceToNow } from 'date-fns';

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formatDate(date, dateFormat) {
  if (!date) {
    return '';
  }
  let mDateFormat = dateFormat;
  if (!dateFormat) {
    mDateFormat = 'dd MMM, yy';
  }
  return format(new Date(date), mDateFormat);
}

export function getDaysAndNights(startDate, endDate) {
  const day1 = new Date(startDate);
  const day2 = new Date(endDate);

  const daysDiff = differenceInCalendarDays(day2, day1);

  return {
    days: daysDiff,
    nights: daysDiff - 1,
  };
}
