/* eslint-disable no-nested-ternary */
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  // OutlinedInput,
  // InputAdornment,
} from '@mui/material';
// components
import { useQuery } from '@tanstack/react-query';
import RFQDetails from './RFQDetails/RFQDetails';
import { getAgencyRFQs } from '../../api/rfqs.api';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import SliderModal from '../../components/slider-modal/SliderModal';
import NewRFQ from './newRFQ/NewRFQ';
import pallete from '../../theme/palette';
import { formatDate } from '../../utils/formatTime';

const TABLE_HEADS = [
  { label: 'PACKAGE' },
  { label: 'ADULTS' },
  { label: 'KIDS' },
  { label: 'INFANTS' },
  { label: 'TRAVEL DATES' },
  { label: 'MEAL PLAN' },
  { label: 'REQUEST DATE' },
  { label: 'STATUS' },
  { label: 'ACTIONS' },
];

export default function AgencyRFQs() {
  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredRFQs, setFilteredRFQs] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [rFQsList, setRFQsList] = useState([]);
  const [newRFQFormOpen, setNewRFQFormOpen] = useState(false);
  const [rFQDetailsOpen, setRFQDetailsOpen] = useState(false);
  const [selectedRFQ, setSelectedRFQ] = useState({});

  const { refetch } = useQuery(['getAgencyRFQs'], getAgencyRFQs, {
    onSuccess: (data) => {
      setRFQsList(data.data || []);
      setFilteredRFQs(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rFQsList.length) : 0;

  return (
    <>
      <Page title="RFQs">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Quote Requests
            </Typography>
            <Button
              variant="contained"
              onClick={() => setNewRFQFormOpen(true)}
              startIcon={<Iconify icon="eva:plus-outline" />}
            >
              New Quote
            </Button>
          </Stack>

          <Card>
            {/* <OutlinedInput
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              sx={{
                margin: 2,
              }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRFQs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        trip,
                        numberOfPeople,
                        numberOfTeens,
                        numberOfKids,
                        startDate,
                        endDate,
                        mealPlan,
                        rfqStatus,
                        createdAt,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt="" src={trip?.featureImage} />
                              <Typography variant="subtitle2" noWrap>
                                {trip?.tripTitle}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{numberOfPeople}</TableCell>
                          <TableCell align="left">{numberOfTeens}</TableCell>
                          <TableCell align="left">{numberOfKids}</TableCell>
                          <TableCell align="left">
                            <Stack>
                              <Stack direction="row">
                                <Typography>{formatDate(startDate)}</Typography>
                                <Typography>-</Typography>
                                <Typography>{formatDate(endDate)}</Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{mealPlan}</TableCell>
                          <TableCell align="left">{formatDate(createdAt)}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="filled"
                              // sx={{color: 'white'}}
                              color={
                                rfqStatus === 'NEW'
                                  ? 'success'
                                  : rfqStatus === 'DECLINED'
                                  ? 'warning'
                                  : rfqStatus === 'OFFER'
                                  ? 'info'
                                  : rfqStatus === 'REJECTED'
                                  ? 'danger'
                                  : 'secondary'
                              }
                            >
                              {sentenceCase(rfqStatus)}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Iconify
                                onClick={() => {
                                  setSelectedRFQ(row);
                                  setRFQDetailsOpen(true);
                                }}
                                icon="eva:info-outline"
                                width={30}
                                height={30}
                                sx={{
                                  color: pallete.info.main,
                                  marginRight: 2,
                                }}
                              />

                              <Iconify
                                onClick={() => {
                                  setSelectedRFQ(row);
                                  setRFQDetailsOpen(true);
                                }}
                                icon="eva:close-circle-outline"
                                width={30}
                                height={30}
                                sx={{
                                  color: pallete.error.main,
                                  marginRight: 2,
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rFQsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <SliderModal openState={newRFQFormOpen} setOpenState={setNewRFQFormOpen}>
        <NewRFQ refetch={refetch} setOpenState={setNewRFQFormOpen} />
      </SliderModal>

      <SliderModal openState={rFQDetailsOpen} setOpenState={setRFQDetailsOpen}>
        <RFQDetails refetch={refetch} setOpenState={setRFQDetailsOpen} RFQ={selectedRFQ} />
      </SliderModal>
    </>
  );
}
