export const tripEndpoints = {
  getFeatured: (category = 'all') => `/trip/featured/${category}`,
  getAllTrips: `/trip/all`,
  getTrip: (tripId) => `/trip/${tripId}`,
  getAgencyTrips: (agencyId) => `/trips/agency/${agencyId}`,
  getAgencyTripsMarketplace: (agencyId, inMarkeplace) => `/trip/agency/${agencyId}/${inMarkeplace}`,
  removeFromMarketplaceSaas: (tripId) => `/trip/removeFromMarketplaceSaas/${tripId}`,
  removeFromMarketplaceOnPrem: (marketplaceId) => `/trip/removeFromMarketplaceOnPrem/${marketplaceId}`,
  getTripPromotions: `/trip/promoted`,
  getPromotedTrips: (promotionId) => `/trip/promoted/${promotionId}`,
  getCategories: `/trip/categories/list`,
  postTrip: `/trip/new`,
  putTrip: (tripId) => `/trip/update/${tripId}`,
  getAgencyBookings: (agencyId) => `/booking/agency/${agencyId}`,
  postToMarketplaceSaas: (tripId) => `/trip/postToMarketplaceSaas/${tripId}`,
  postToMarketplaceOnPrem: (agencyMarketplaceId) => `/trip/postToMarketplaceOnPrem/${agencyMarketplaceId}`,
  updateMarketplaceOnPrem: (tripMarketplaceId) => `/trip/updateMarketplaceOnPrem/${tripMarketplaceId}`,
  deleteTrip: (tripId) => `/trip/${tripId}`,
  tripActivity: (agencyId) => `/agency/trip/activities/${agencyId}`,
};
