import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Iconify from '../../components/Iconify';
import DateRangePicker from '../../components/DateRangePicker';
import { fCurrency } from '../../utils/formatNumber';
import Page from '../../components/Page';
import { getAgencyBookingReports } from '../../api/bookings.api';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

const BookingReports = () => {
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [bookingsList, setBookingsList] = useState([]);
  const [, setSelectedDates] = useState({});
  const [selectedTrip, setSelectedTrip] = useState({});

  useQuery(['getAgencyBookingReports'], () => getAgencyBookingReports(), {
    onSuccess: (data) => {
      setBookingsList(data.data || []);
      setFilteredBookings(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingsList.length) : 0;

  return (
    <>
      <Page title="Booking Reports">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Booking Reports
            </Typography>
          </Stack>

          <Card sx={{ marginBottom: 2 }}>
            <Typography marginTop={1} paddingX={2}>
              Search or Filter
            </Typography>
            <Stack direction="row" alignItems="center" spacing={3} padding={2}>
              <OutlinedInput
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                fullWidth
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                size="small"
              />

              <Autocomplete
                renderInput={(params) => <TextField size="small" {...params} label="Package" />}
                options={bookingsList || []}
                getOptionLabel={(option) => option.tripName || ''}
                value={selectedTrip || ''}
                fullWidth
                onChange={(_, newValue) => setSelectedTrip(newValue)}
                size="small"
              />

              <DateRangePicker
                onSelect={(dateRanges) => {
                  setSelectedDates(dateRanges);
                  console.log(dateRanges);
                }}
                size="small"
              />

              <Box>
                <Button variant="outlined" sx={{ width: 150 }} endIcon={<Iconify icon="ion:filter" />}>
                  Apply Filters
                </Button>
              </Box>
            </Stack>
          </Card>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}>TRIP PACKAGES</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        PORTAL
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        MARKETPLACE
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                        ALL
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Package Name</TableCell>
                      <TableCell>Bookings</TableCell>
                      <TableCell>Revenue</TableCell>
                      <TableCell>Bookings</TableCell>
                      <TableCell>Revenue</TableCell>
                      <TableCell>Bookings</TableCell>
                      <TableCell>Revenue</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const {
                        tripName,
                        bookings,
                        portalBookings,
                        travenaBookings,
                        totalRevenue,
                        totalBookings,
                        portalRevenue,
                        travenaRevenue,
                      } = row;

                      return (
                        <>
                          <TableRow sx={{ bgcolor: index % 2 === 0 ? '#f8f8ff' : '' }}>
                            <TableCell colSpan={7} sx={{ fontWeight: 'bold', fontSize: 16 }}>
                              {tripName}
                            </TableCell>
                          </TableRow>

                          {bookings?.map((booking) => (
                            <TableRow hover key={booking.packageId} sx={{ bgcolor: index % 2 === 0 ? '#f8f8ff' : '' }}>
                              <TableCell>{booking.packageName}</TableCell>
                              <TableCell>{booking.portalBookings}</TableCell>
                              <TableCell>KSh {fCurrency(booking.portalRevenue)}</TableCell>
                              <TableCell>{booking.travenaBookings}</TableCell>
                              <TableCell>KSh {fCurrency(booking.travenaRevenue)}</TableCell>
                              <TableCell>{booking.totalBookings}</TableCell>
                              <TableCell>KSh {fCurrency(booking.totalRevenue)}</TableCell>
                            </TableRow>
                          ))}

                          <TableRow hover sx={{ bgcolor: index % 2 === 0 ? '#f8f8ff' : '' }}>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              Total
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              {portalBookings}
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              KSh {fCurrency(portalRevenue)}
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              {travenaBookings}
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              KSh {fCurrency(travenaRevenue)}
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              {totalBookings}
                            </TableCell>
                            <TableCell sx={{ color: '#00AB66', fontWeight: 'bold', borderBottom: '1px solid #c2c2c2' }}>
                              KSh {fCurrency(totalRevenue)}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bookingsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default BookingReports;
