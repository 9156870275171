/* eslint-disable react/prop-types */
import { Button, Card, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Iconify from '../../../components/Iconify';

const RFQQOfferForm = (props) => {
  const { setOpenState, dispatch, rfqId } = props;

  const [packageName, setPackageName] = useState('');
  const [packageCost, setPackageCost] = useState('');
  const [description, setDescription] = useState('');
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);

  const [inclusionTemp, setInclusionTemp] = useState('');
  const [exclusionTemp, setExclusionTemp] = useState('');

  const addCostPackage = (addAnother) => {
    if (packageName && packageCost) {
      dispatch({
        action: 'add',
        value: {
          packageName,
          packageCost,
          packageDescription: description,
          inclusions,
          exclusions,
          parentRFQ: {
            id: rfqId,
          },
        },
      });
    }
    setPackageName('');
    setPackageCost('');
    setDescription('');
    setInclusions([]);
    setInclusions([]);

    if (!addAnother) {
      setOpenState(false);
    }
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Add RFQ Offer</div>
        <div className="slider-header-buttons">
          <Button
            variant="outlined"
            sx={{
              marginRight: 2,
            }}
            onClick={() => setOpenState(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              marginRight: 2,
            }}
            variant="contained"
            onClick={() => addCostPackage(true)}
          >
            Save & Add Another
          </Button>
          <Button variant="contained" onClick={() => addCostPackage(false)}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <Card sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                value={packageName}
                label="Package name"
                required
                type="text"
                onChange={(e) => setPackageName(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={packageCost}
                label="Package cost"
                required
                type="number"
                onChange={(e) => setPackageCost(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Stack marginY={2}>
            <TextareaAutosize
              style={{ padding: 3, width: '100%' }}
              value={description}
              minRows={3}
              label="Description"
              placeholder="Description..."
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </Stack>

          <Stack direction="column" spacing={2}>
            <Typography>Package Includes</Typography>
            {inclusions.map((inclusion, index) => (
              <Stack direction="row" key={`${index}`} alignContent="center" alignItems="center" spacing={5}>
                <Typography>#{index + 1}</Typography>
                <TextField
                  type="text"
                  label="Inclusion"
                   size='small'
                  value={inclusion}
                  onChange={(e) => {
                    setInclusionTemp(e.target.value);
                  }}
                  disabled
                />
                <Iconify sx={{ fontSize: 25 }} icon="eva:close-outline" />
              </Stack>
            ))}

            <Stack direction="row" spacing={5} alignItems="center" sx={{ paddingBottom: 5 }}>
              <Typography variant="h5">+</Typography>
              <TextField
                type="text"
                label="Add Inclusion"
                 size='small'
                value={inclusionTemp}
                onChange={(e) => {
                  setInclusionTemp(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  if (inclusionTemp) {
                    setInclusions([...inclusions, inclusionTemp]);
                    setInclusionTemp('');
                  }
                }}
              >
                Add
              </Button>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={2}>
            <Typography>Package Excludes</Typography>
            {exclusions.map((inclusion, index) => (
              <Stack direction="row" key={`${index}`} alignContent="center" alignItems="center" spacing={5}>
                <Typography>#{index + 1}</Typography>
                <TextField
                  type="text"
                  label="Inclusion"
                  size='small'
                  value={inclusion}
                  onChange={(e) => {
                    setInclusionTemp(e.target.value);
                  }}
                  disabled
                />
                <Iconify sx={{ fontSize: 25 }} icon="eva:close-outline" />
              </Stack>
            ))}

            <Stack direction="row" spacing={5} alignItems="center" sx={{ paddingBottom: 10 }}>
              <Typography variant="h5">+</Typography>
              <TextField
                type="text"
                label="Add Exclusion"
                 size='small'
                value={exclusionTemp}
                onChange={(e) => {
                  setExclusionTemp(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  if (exclusionTemp) {
                    setExclusions([...exclusions, exclusionTemp]);
                    setExclusionTemp('');
                  }
                }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Card>
      </div>
    </div>
  );
};

export default RFQQOfferForm;
