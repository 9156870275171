import {
  Box,
  Button,
  Card,
  Container,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import Page from '../../components/Page';
import { getCookie, setCookie } from '../../utils/cookies';
import {
  enrollMarketPlaceOnPrem,
  enrollToMarketPlaceSaas,
  getAgencyDetails,
  // getAgencyTypes,
  updateAgency,
  updateAgencyProfile,
} from '../../api/agency.api';
import { Toast } from '../../axios/Toast';

export default function AgencyProfile() {
  const { agencyId, hasMarketplace } = getCookie('UserInfo');
  const { data: agencyDetails, refetch } = useQuery(['getAgencyDetails'], () => getAgencyDetails(agencyId));
  // const { data: agencyTypes } = useQuery(['getAgencyTypes'], getAgencyTypes);

  const formData = new FormData();
  const fileInputRef = useRef();

  const [agencyName, setAgencyName] = useState(agencyDetails?.data?.name || '');
  const [agencyDescription, setAgencyDescription] = useState(agencyDetails?.data?.agencyDescription || '');

  const HA = () => {
    formData.set('name', agencyName);
    formData.set('agencyDescription', agencyDescription);
    updateAgencyProfileMutation();
  };

  const { mutate: updateAgencyProfileMutation } = useMutation(
    ['updateAgencyProfile'],
    () => updateAgencyProfile(formData),
    {
      onSuccess: (response) => {
        if (response.success) {
          Toast.success('Profile Updated');
          refetch();
          const newValue = {
            ...getCookie('UserInfo'),
            logoImageUrl: response.data.logoImageUrl,
            agencyName: response.data.name,
          };

          setCookie('UserInfo', newValue);
        }
      },
      onError: () => {
        Toast.error('An error occurred.');
      },
    }
  );

  const { mutate: updateAgencyDetailsMutation } = useMutation(['updateAgency'], (data) => updateAgency(data), {
    onSuccess: (response) => {
      if (response.success) {
        const uInfo = getCookie('UserInfo');
        setCookie('UserInfo', { ...uInfo, hasMarketplace: true });
        Toast.success(response.message);
        window.location.reload();
      } else {
        Toast.error('Unable to update enrollment status');
      }
    },
    onError: () => {
      Toast.error('An error occurred.');
    },
  });

  const { mutate: enrollMarketPlaceOnPremMutation } = useMutation(
    ['enrollMPOnPrem'],
    (data) => enrollMarketPlaceOnPrem(data),
    {
      onSuccess: (response) => {
        if (response.success) {
          const detailsCp = { ...agencyDetails };
          detailsCp.data.hasMarketplace = true;
          detailsCp.data.marketPlaceId = response.data.marketplaceId;
          updateAgencyDetailsMutation(detailsCp.data);
        } else {
          Toast.error('Unable to enroll to marketplace');
        }
      },
      onError: () => {
        Toast.error('An error occurred.');
      },
    }
  );

  const { mutate: enrollMarketPlaceSaasMutation } = useMutation(['enrollMPSaas'], enrollToMarketPlaceSaas, {
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
        const uInfo = getCookie('UserInfo');
        setCookie('UserInfo', { ...uInfo, hasMarketplace: true });
        Toast.success(response.message);
        window.location.reload();
      } else {
        Toast.error('Unable to enroll to marketplace');
      }
    },
    onError: () => {
      Toast.error('An error occurred.');
    },
  });

  const enrollToMarketPlace = () => {
    Toast.loading('Please wait..');
    if (process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL-SAAS') {
      enrollMarketPlaceSaasMutation();
    } else {
      enrollMarketPlaceOnPremMutation(agencyDetails?.data);
    }
  };

  return (
    <>
      <Page title="Agency Profile">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Edit Agency Profile
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                Toast.loading('Please wait');
                // updateAgencyProfileMutation();
                HA();
              }}
            >
              Save Changes
            </Button>
          </Stack>

          <Stack direction="column" spacing={2}>
            <Card style={{ padding: 20 }}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Typography marginBottom={1} variant="h5">
                    Public Profile
                  </Typography>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <img style={{ width: 100, height: 100 }} src={agencyDetails?.data?.logoImageUrl} alt="photoURL" />

                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={(e) => {
                          if (e.target.files.length) {
                            formData.set('agencyProfilePic', e.target.files[0]);
                          }
                        }}
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          width: 70,
                          height: 70,
                          borderRadius: 3,
                          borderStyle: 'dashed',
                          fontWeight: 'light',
                          fontSize: 7,
                        }}
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        upload new profile picture
                      </Button>
                    </Box>

                    <TextField
                      name="agencyName"
                      label="Name"
                      fullWidth
                      value={agencyName}
                      onChange={(e) => {
                        setAgencyName(e.target.value);
                      }}
                      InputLabelProps={{ shrink: agencyDetails?.data?.name }}
                    />

                    {/* <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Agency Type</InputLabel>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Agency Types">
                        {agencyTypes &&
                          agencyTypes.map((agencyType) => (
                            <MenuItem key={agencyType} value={agencyType}>
                              {agencyType}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl> */}
                  </Stack>
                </Box>
              </Stack>
            </Card>

            <Card style={{ padding: 20 }}>
              <Stack direction="column" spacing={2}>
                <Typography marginBottom={1} variant="h5">
                  Description
                </Typography>

                <Stack direction="row" spacing={2}>
                  <TextField
                    name="agencyDescription"
                    label="Describe what you offer"
                    fullWidth
                    multiline
                    value={agencyDescription}
                    InputLabelProps={{ shrink: agencyDetails?.data?.agencyDescription }}
                    onChange={(e) => {
                      setAgencyDescription(e.target.value);
                    }}
                  />
                </Stack>
              </Stack>
            </Card>

            <Card style={{ padding: 20 }}>
              <Stack direction="column" spacing={2}>
                <Typography marginBottom={3} variant="h5">
                  Other Details
                </Typography>

                <Stack direction="row" spacing={2}>
                  <TextField
                    name="traNumber"
                    label="TRA Number"
                    fullWidth
                    disabled
                    value={agencyDetails?.data?.traNumber}
                    InputLabelProps={{ shrink: agencyDetails?.data?.traNumber }}
                  />

                  <TextField
                    name="officePhoneNumber"
                    label="Office Number"
                    fullWidth
                    disabled
                    value={agencyDetails?.data?.officePhoneNumber}
                    InputLabelProps={{ shrink: agencyDetails?.data?.officePhoneNumber }}
                  />
                </Stack>
              </Stack>
            </Card>

            <Card style={{ padding: 20 }}>
              <Stack direction="column" spacing={2}>
                <Typography marginBottom={1} variant="h5">
                  Marketplace
                </Typography>

                {hasMarketplace && (
                  <Stack spacing={2} justifyContent="center" alignItems="center">
                    <Typography>You have already enrolled to Travena marketplace</Typography>
                    <Button variant="outlined">Withdraw</Button>
                  </Stack>
                )}

                {!hasMarketplace && (
                  <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
                    <Typography variant="h6">You have not enrolled to Travena Marketplace</Typography>
                    <Typography maxWidth={700} textAlign="center">
                      Enrolling enables you to post your trips to Travena.io, and the Travena APP where you can leverage
                      our large traffic to view your offers.{' '}
                    </Typography>
                    <Button variant="contained" onClick={() => enrollToMarketPlace()}>
                      Enroll Now (Free)
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Card>
          </Stack>
        </Container>
      </Page>
    </>
  );
}
