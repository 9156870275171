import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  // OutlinedInput,
  // InputAdornment,
} from '@mui/material';
// components
import { useQuery } from '@tanstack/react-query';
import { getTripActivities } from '../../api/trips.api';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { formatDate } from '../../utils/formatTime';
import Iconify from '../../components/Iconify';
import pallete from '../../theme/palette';
import SliderModal from '../../components/slider-modal/SliderModal';
import TripActivityDetails from './activityDetails/ActivityDetails';

const TABLE_HEADS = [
  { label: 'PACKAGE' },
  { label: 'DATES' },
  { label: 'TOTAL PERSONEL' },
  { label: 'TOTAL EQUIPMENTS' },
  { label: 'STARTED BY' },
  { label: 'DATE STARTED' },
  { label: 'ACTIONS' },
];

export default function TripActivities() {
  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [tripsList, setTripsList] = useState([]);
  const [activityDetailOpen, setActivityDetailOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();

  useQuery(['getTripsActivity'], getTripActivities, {
    onSuccess: (data) => {
      setTripsList(data.data || []);
      setFilteredTrips(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tripsList.length) : 0;

  return (
    <>
      <Page title="Trip activities">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Trip activities
            </Typography>
          </Stack>

          <Card>
            {/* <OutlinedInput
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              sx={{
                margin: 2,
              }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTrips.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { associatedTrip, associatedCalendar, operators, equipments, createdBy, dateCreated } = row;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ bgcolor: index % 2 === 0 ? '#FDFDFD' : '' }}
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {associatedTrip.tripTitle}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{`${formatDate(associatedCalendar?.startDate)} - ${formatDate(
                            associatedCalendar?.endDate
                          )}`}</TableCell>
                          <TableCell align="left">{operators.length || 0}</TableCell>
                          <TableCell align="left">{equipments.length || 0}</TableCell>
                          <TableCell align="left">{createdBy.userProfile.fullName}</TableCell>
                          <TableCell>{formatDate(dateCreated)}</TableCell>

                          <TableCell align="left">
                            <Iconify
                              icon="eva:info-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.info.main,
                                marginRight: 2,
                              }}
                              onClick={() => {
                                setSelectedActivity(row);
                                setActivityDetailOpen(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tripsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

          <SliderModal openState={activityDetailOpen} setOpenState={setActivityDetailOpen}>
            <TripActivityDetails setOpenState={setActivityDetailOpen} activityDetail={selectedActivity} />
          </SliderModal>
        </Container>
      </Page>
    </>
  );
}
