/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/prop-types */
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { showToast } from '../../../utils/showToast';
import Iconify from '../../../components/Iconify';
import SliderModal from '../../../components/slider-modal/SliderModal';
import TripCalendar from './form-sections/Calendar';
import TripCostPackages from './form-sections/CostPackages';
import TripExclusions from './form-sections/Exclusions';
import TripServices from './form-sections/Inclusions';
import Iteneraries from './form-sections/Iteneraries';
import { getTripCategories } from '../../../api/trips.api';
import { formatDate } from '../../../utils/formatTime';

const ITENERARIES_TABLE_HEADER = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'name', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'actions', label: '', alignRight: false },
];

const SERVICES_TABLE_HEADER = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'service', label: 'Service' },
  { id: 'actions', label: '', alignRight: false },
];

const EXCLUSIONS_TABLE_HEADER = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'exclusion', label: 'Exclusion' },
  { id: 'actions', label: '', alignRight: false },
];

const PACKAGES_TABLE_HEADER = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'name', label: 'Package Name', alignRight: false },
  { id: 'mealPlan', label: 'Meal Plan', alignRight: false },
  { id: 'roomType', label: 'Room Type', alignRight: false },
  { id: 'cost', label: 'Package Cost', alignRight: false },
  { id: 'inclusions', label: 'Extras', alignRight: false },
  { id: 'actions', label: '', alignRight: false },
];

const CALENDAR_TABLE_HEADER = [
  { id: 'index', label: '#', alignRight: false },
  { id: 'startDate', label: 'Start Date', alignRight: false },
  { id: 'endDate', label: 'End Date', alignRight: false },
  { id: 'availableSlots', label: 'Available Slots', alignRight: false },
  { id: 'actions', label: '', alignRight: false },
];

export default function TripForm({ state, dispatch, submitted }) {
  const [destinationName, setDestinationName] = useState(state.destinationName || '');
  const [destinationCountry, setDestinationCountry] = useState(state.destinationCountry || '');
  const [tripTitle, setTripTitle] = useState(state.tripTitle || '');
  const [tripStatus, setTripStatus] = useState(state.tripStatus || '');
  const [allowPartialBooking, setAllowPartialBooking] = useState(state.allowPartialBooking || '');
  const [tripCategories, setTripCategories] = useState(state.tripCategories || '');
  const [tripDescription, setTripDescription] = useState(state.tripDescription || '');
  const [iteneraries, setIteneraries] = useState(state.tripIteneraries || []);
  const [services, setServices] = useState(state.tripServices || []);
  const [exclusions, setExclustions] = useState(state.tripExclusions || []);
  const [costPackages, setCostPackages] = useState(state.tripCostPackages || []);
  const [tripCalendar, setCalendar] = useState(state.tripCalendar || []);
  const [destinationImagery] = useState(state.destinationImagery || []);
  const [allowRFQ, setAllowRFQ] = useState(Boolean(state.allowRFQ));
 
  const [itenerariesFormOpen, setItenerariesFormOpen] = useState(false);
  const [servicesFormOpen, setServicesFormOpen] = useState(false);
  const [exclusionFormOpen, setExclusionFormOpen] = useState(false);
  const [tripCostPackagesOpen, setTripCostPackagesOpen] = useState(false);
  const [calendarFormOpen, setCalendarFormOpen] = useState(false);

  const tripStatuses = ['ACTIVE', 'INACTIVE'];
  const { data: categories } = useQuery(['getCategories'], () => getTripCategories());

  return (
    <div>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 3 }}>
          General Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              type="text"
              label="Trip title *"
              value={tripTitle || ''}
              onChange={(e) => {
                setTripTitle(e.target.value);
                dispatch({ name: 'tripTitle', value: e.target.value });
              }}
              error={submitted && !state?.tripTitle}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Trip Destination *"
              value={destinationName || ''}
              onChange={(e) => {
                setDestinationName(e.target.value);
                dispatch({ name: 'destinationName', value: e.target.value });
              }}
              error={submitted && !state?.destinationName}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Destination country *"
              value={destinationCountry || ''}
              onChange={(e) => {
                setDestinationCountry(e.target.value);
                dispatch({ name: 'destinationCountry', value: e.target.value });
              }}
              fullWidth
              error={submitted && !state?.destinationCountry}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="cat-label">Trip Categories</InputLabel>
              <Select
                type="text"
                label="Trip Categories *"
                labelId="cat-label"
                value={tripCategories || []}
                onChange={(e) => {
                  setTripCategories(e.target.value);
                  dispatch({ name: 'tripCategories', value: e.target.value });
                }}
                fullWidth
                multiple
              >
                {categories?.data?.map((category) => (
                  <MenuItem key={category.categoryName} value={category.categoryName}>
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="st-label">Status</InputLabel>
              <Select
                value={tripStatus || 'ACTIVE'}
                onChange={(e) => {
                  setTripStatus(e.target.value);
                  dispatch({ name: 'tripStatus', value: e.target.value });
                }}
                label="Status"
                labelId="st-label"
                fullWidth
              >
                <MenuItem disabled>{''}</MenuItem>
                {tripStatuses.map((sts) => (
                  <MenuItem key={sts} value={sts}>
                    {sts}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="pb-label">Allow Request For Quote</InputLabel>
              <Select
                label="Allow Request For Quote"
                value={allowRFQ ? 'Yes' : 'No'}
                onChange={(e) => {
                  setAllowRFQ(e.target.value === 'Yes');
                  dispatch({ name: 'allowRFQ', value: e.target.value === 'Yes' });
                }}
                labelId="pb-label"
                fullWidth
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="pb-label">Allow partial booking</InputLabel>
              <Select
                label="Allow partial booking"
                value={allowPartialBooking ? 'Yes' : 'No'}
                onChange={(e) => {
                  setAllowPartialBooking(e.target.value === 'Yes');
                  dispatch({ name: 'allowPartialBooking', value: e.target.value === 'Yes' });
                }}
                labelId="pb-label"
                fullWidth
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <TextareaAutosize
              style={{ padding: 3, width: '100%' }}
              minRows={4}
              label="Description *"
              placeholder="Description..."
              value={tripDescription || ''}
              onChange={(e) => {
                setTripDescription(e.target.value);
                dispatch({ name: 'tripDescription', value: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Iteneraries
            </Typography>

            <Button variant="outlined" onClick={() => setItenerariesFormOpen(true)}>
              Add Itenerary
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {ITENERARIES_TABLE_HEADER.map((row) => (
                    <TableCell key={row.id}>
                      <Typography variant="subtitle1">{row.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {iteneraries.map((itenerary, index) => (
                  <TableRow key={itenerary.name} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{itenerary.name}</TableCell>
                    <TableCell>{itenerary.description}</TableCell>
                    <TableCell>
                      <Iconify
                        sx={{
                          fontSize: 25,
                        }}
                        icon="eva:close-outline"
                        onClick={() => {
                          const newItns = [...iteneraries];
                          newItns.splice(index, 1);
                          setIteneraries(newItns);
                          dispatch({ name: 'removeItenerary', index });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Services Included
            </Typography>

            <Button variant="outlined" onClick={() => setServicesFormOpen(true)}>
              Add Service
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {SERVICES_TABLE_HEADER.map((row) => (
                    <TableCell key={row.id}>
                      <Typography variant="subtitle1">{row.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {services.map((service, index) => (
                  <TableRow key={service} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{service}</TableCell>
                    <TableCell>
                      <Iconify
                        sx={{
                          fontSize: 25,
                        }}
                        icon="eva:close-outline"
                        onClick={() => {
                          const newItns = [...services];
                          newItns.splice(index, 1);
                          setServices(newItns);
                          dispatch({ name: 'removeService', index });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Exclusions
            </Typography>

            <Button variant="outlined" onClick={() => setExclusionFormOpen(true)}>
              Add Exclusion
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {EXCLUSIONS_TABLE_HEADER.map((row) => (
                    <TableCell key={row.id}>
                      <Typography variant="subtitle1">{row.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {exclusions.map((exclusion, index) => (
                  <TableRow key={exclusion} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{exclusion}</TableCell>
                    <TableCell>
                      <Iconify
                        sx={{
                          fontSize: 25,
                        }}
                        icon="eva:close-outline"
                        onClick={() => {
                          const newItns = [...exclusions];
                          newItns.splice(index, 1);
                          setExclustions(newItns);
                          dispatch({ name: 'removeExclustion', index });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Cost Packages
            </Typography>

            <Button variant="outlined" onClick={() => setTripCostPackagesOpen(true)}>
              Add Package
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {PACKAGES_TABLE_HEADER.map((row) => (
                    <TableCell key={row.id}>
                      <Typography variant="subtitle1">{row.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {costPackages.map((costPackage, index) => (
                  <TableRow key={costPackage.name} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{costPackage.packageName}</TableCell>
                    <TableCell>{costPackage.mealPlan}</TableCell>
                    <TableCell>{costPackage.roomType}</TableCell>
                    <TableCell>{costPackage.packageCost}</TableCell>
                    <TableCell>
                      {costPackage?.inclusions?.map((inc) => (
                        <Typography key={inc} variant="caption">
                          {inc}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      <Iconify
                        sx={{
                          fontSize: 25,
                        }}
                        icon="eva:close-outline"
                        onClick={() => {
                          const newItns = [...costPackages];
                          newItns.splice(index, 1);
                          setCostPackages(newItns);
                          dispatch({ name: 'removePackageCost', index });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Calendar
            </Typography>

            <Button variant="outlined" onClick={() => setCalendarFormOpen(true)}>
              Add Calendar
            </Button>
          </Stack>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {CALENDAR_TABLE_HEADER.map((row) => (
                    <TableCell key={row.id}>
                      <Typography variant="subtitle1">{row.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {tripCalendar.map((calendar, index) => (
                  <TableRow key={`${index}`} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{formatDate(calendar.startDate, 'dd MMM, yyyy')}</TableCell>
                    <TableCell>{formatDate(calendar.endDate, 'dd MMM, yyyy')}</TableCell>
                    <TableCell>{calendar.availableSlots}</TableCell>
                    <TableCell>
                      <Iconify
                        sx={{
                          fontSize: 25,
                        }}
                        icon="eva:close-outline"
                        onClick={() => {
                          const newItns = [...tripCalendar];
                          newItns.splice(index, 1);
                          setCalendar(newItns);
                          dispatch({ name: 'removeCalender', index });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
              Feature Image
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <img style={{ height: 120, width: 250 }} src={state.featureImage} alt="Not Saved" />

            <Typography>Select image</Typography>
            <TextField
              type="file"
              label="Add or Change Feature Image"
              onChange={(e) => dispatch({ name: 'addFeatureImage', value: e.target.files[0] })}
              inputProps={{ accept: 'image/*' }}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
      </Card>

      <Card sx={{ padding: 3, marginTop: 3 }}>
        <Stack direction="column" spacing={3}>
          <Typography variant="subtitle1">Imagery</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <ImageList>
              {destinationImagery.map((image) => (
                <ImageListItem key={image}>
                  <img src={image} loading="lazy" alt="Imagery" />
                  <ImageListItemBar
                    title=""
                    subtitle=""
                    actionIcon={
                      <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label="Remove">
                        <Iconify icon="cancel" />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Stack>
          <Typography>Add Images (5 images max)</Typography>
          <TextField
            id="assets"
            name="assets"
            type="file"
            inputProps={{ multiple: true, accept: 'image/*' }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const fileCount = e.target.files.length;

              if (fileCount > 5 - Number(state.destinationImagery?.length)) {
                showToast({ type: 'error', message: 'Selected images exceed limit' });
                return false;
              }

              for (let i = 0; i < 5; i++) {
                dispatch({ name: 'addImagery', value: e.target.files[i] });
              }
              return true;
            }}
          />
        </Stack>
      </Card>

      <SliderModal from="bottom" height={75} openState={itenerariesFormOpen} setOpenState={setItenerariesFormOpen}>
        <Iteneraries
          setOpenState={setItenerariesFormOpen}
          dispatch={({ name, value }) => {
            dispatch({ name, value });
            setIteneraries([...iteneraries, value]);
          }}
        />
      </SliderModal>

      <SliderModal from="bottom" height={75} openState={servicesFormOpen} setOpenState={setServicesFormOpen}>
        <TripServices
          setOpenState={setServicesFormOpen}
          dispatch={({ name, value }) => {
            dispatch({ name, value });
            setServices([...services, value]);
          }}
        />
      </SliderModal>

      <SliderModal from="bottom" height={75} openState={exclusionFormOpen} setOpenState={setExclusionFormOpen}>
        <TripExclusions
          setOpenState={setExclusionFormOpen}
          dispatch={({ name, value }) => {
            dispatch({ name, value });
            setExclustions([...exclusions, value]);
          }}
        />
      </SliderModal>

      <SliderModal from="bottom" height={75} openState={tripCostPackagesOpen} setOpenState={setTripCostPackagesOpen}>
        <TripCostPackages
          setOpenState={setTripCostPackagesOpen}
          dispatch={({ name, value }) => {
            dispatch({ name, value });
            setCostPackages([...costPackages, value]);
          }}
        />
      </SliderModal>

      <SliderModal from="bottom" height={75} openState={calendarFormOpen} setOpenState={setCalendarFormOpen}>
        <TripCalendar
          setOpenState={setCalendarFormOpen}
          dispatch={({ name, value }) => {
            dispatch({ name, value });
            setCalendar([...tripCalendar, value]);
          }}
        />
      </SliderModal>
    </div>
  );
}
