/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import {
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sentenceCase } from 'change-case';
import SliderModal from '../../../components/slider-modal/SliderModal';
import RFQQOfferForm from '../OfferForm/CreateOfferForm';
import { Toast } from '../../../axios/Toast';
import { createRFQOffers } from '../../../api/rfqs.api';
import Iconify from '../../../components/Iconify';
import { formatDate, getDaysAndNights } from '../../../utils/formatTime';
import Label from '../../../components/Label';

const TABLE_HEADS = [{ label: 'Package name' }, { label: 'Cost' }, { label: 'Details' }, { label: 'Remove' }];

export default function RFQDetails({ setOpenState, RFQ, refetch }) {
  const [offers, setOffers] = useState(RFQ.offers || []);
  const [newOfferOpen, setNewOfferOpen] = useState(false);

  const { rfqStatus } = RFQ;

  const { mutate: createOfferMutation } = useMutation(['updateAgencyProfile'], () => createRFQOffers(RFQ.id, offers), {
    onSuccess: (response) => {
      if (response.success) {
        Toast.success('Offers were sent to the customer');
        refetch();
      }
    },
  });

  const handleSubmit = () => {
    Toast.loading('Please wait...');
    createOfferMutation();
  };

  const dispatch = ({ action, value }) => {
    if (action === 'add') {
      setOffers([...offers, value]);
    }

    if (action === 'remove') {
      const tempOffers = [...offers];
      tempOffers.splice(value, 1);
      setOffers(tempOffers);
    }
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div>
          <Stack direction="row" spacing={1}>
            <Typography>RFQ Details</Typography>
            <Label
              variant="filled"
              // sx={{color: 'white'}}
              color={
                rfqStatus === 'NEW'
                  ? 'success'
                  : rfqStatus === 'DECLINED'
                  ? 'warning'
                  : rfqStatus === 'OFFER'
                  ? 'info'
                  : rfqStatus === 'REJECTED'
                  ? 'danger'
                  : 'secondary'
              }
            >
              {sentenceCase(rfqStatus)}
            </Label>
          </Stack>
          <div className="slider-header-title">Trip: {RFQ.trip.tripTitle}</div>
        </div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleSubmit()}>
            Respond
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <Stack spacing={2}>
          <Typography variant="h6">RFQ Summary</Typography>
          <Card sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField fullWidth label="Adults 16+ yrs" value={RFQ.numberOfPeople} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField fullWidth label="Kids above 10 yrs" value={RFQ.numberOfTeens} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField fullWidth label="Kids 10 yrs below" value={RFQ.numberOfKids} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField fullWidth label="Meal Plan" value={RFQ.mealPlan} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Travel Dates"
                  value={`${formatDate(RFQ.startDate)} - ${formatDate(RFQ.endDate)}`}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Travel Duration"
                  value={`${getDaysAndNights(RFQ.startDate, RFQ.endDate).days} Days, ${
                    getDaysAndNights(RFQ.startDate, RFQ.endDate).nights
                  } Nights`}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField multiline fullWidth label="Customer notes" value={RFQ.customerNotes} disabled />
              </Grid>
            </Grid>
          </Card>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Offers</Typography>
            <Button variant="outlined" onClick={() => setNewOfferOpen(true)}>
              New Offer
            </Button>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: '#00ab66cc',
                  }}
                >
                  <TableRow>
                    {TABLE_HEADS.map((header) => (
                      <TableCell key={header.label}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers.map((offer, indexOff) => (
                    <TableRow key={offer.packageName}>
                      <TableCell>{offer.packageName}</TableCell>
                      <TableCell>{offer.packageCost}</TableCell>
                      <TableCell>
                        <Stack spacing={1}>
                          <Typography variant="caption" fontWeight="bold">
                            Includes
                          </Typography>
                          {offer.inclusions.map((inc, index) => (
                            <Typography key={inc}>
                              {index + 1}. {inc}{' '}
                            </Typography>
                          ))}

                          <Typography variant="caption" fontWeight="bold">
                            Excludes
                          </Typography>
                          {offer.exclusions.map((inc, index) => (
                            <Typography key={inc}>
                              {index + 1}. {inc}{' '}
                            </Typography>
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Iconify
                          onClick={() => dispatch({ action: 'remove', value: indexOff })}
                          icon="eva:close-outline"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          {!offers.length && (
            <Stack spacing={1} alignItems="center" padding={2} bgcolor="ButtonShadow" borderRadius={1}>
              <Typography>No offers have been created for this RFQ.</Typography>
              <Typography textAlign="center">
                Create offers and click Respond to Accept the RFQ. The customer will receive the offers and select/book
                the one that suits them.
              </Typography>
            </Stack>
          )}

          <SliderModal from="bottom" height={75} openState={newOfferOpen} setOpenState={setNewOfferOpen}>
            <RFQQOfferForm setOpenState={setNewOfferOpen} dispatch={dispatch} rfqId={RFQ.id} />
          </SliderModal>
        </Stack>
      </div>
    </div>
  );
}
