// component
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/agent/dashboard',
    icon: getIcon('eva:grid-fill'),
  },
  {
    title: 'Packages',
    path: '/agent/trips',
    icon: getIcon('eva:map-fill'),
  },
  {
  title: 'Quote requests',
  path: '/agent/quote-requests',
  icon: getIcon('material-symbols:request-quote')
  },
  {
    title: 'Bookings',
    path: '/agent/bookings',
    icon: getIcon('icon-park-solid:ticket'),
  },
  {
    title: 'Booking reports',
    path: '/agent/booking-reports',
    icon: getIcon('mdi:report-arc'),
  },
  {
    title: 'Marketplace',
    path: '/agent/marketplace',
    icon: getIcon('mdi:marketplace'),
  },
  {
    title: 'Activities',
    path: '/agent/activities',
    icon: getIcon('material-symbols:browse-activity-rounded'),
  },
  {
    title: 'Settlements',
    path: '/agent/settlements',
    icon: getIcon('solar:money-bag-bold'),
  },
  {
    title: 'Personel & Equipments',
    path: '/agent/operators',
    icon: getIcon('streamline:office-worker-solid'),
  },
  {
    title: 'Users & Roles',
    path: '/agent/user-and-roles',
    icon: getIcon('fa6-solid:users-gear'),
  },
  {
    title: 'Settings',
    path: '/agent/settings',
    icon: getIcon('eva:settings-2-fill'),
  },
];

export default navConfig;
