import React, { useReducer, useState } from 'react';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import BookingForm from '../bookingForm/BookingForm';
import bookingFormReducer from '../bookingForm/bookingFormReducer';
import { getCookie } from '../../../utils/cookies';
import { createBooking } from '../../../api/bookings.api';
import { Toast } from '../../../axios/Toast';

// eslint-disable-next-line react/prop-types
const NewBooking = ({ setOpenState, refetch }) => {
  const { agencyId } = getCookie('UserInfo');

  const [hasSubmit, setHasSubmit] = useState(false);
  const [state, dispatch] = useReducer(bookingFormReducer, {
    agencyId,
    numberOfPeople: 1,
    bookingType: 'Full',
    paymentStatus: 'PAID',
  });

  const createBookingMtn = useMutation(['createBookingMtn'], () => createBooking(state), {
    onSettled: () => {
      refetch();
      setOpenState(false);
      setHasSubmit(false);
    },
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
      } else {
        Toast.error(response.message);
      }
    },
    onError: () => {
      Toast.error('Error occurred');
    },
  });

  const onSave = () => {
    setHasSubmit(true);
    
    if (
      !state?.bookingContact?.fullName ||
      !state?.bookingContact?.phoneNumber ||
      !state?.bookingContact?.email ||
      !state?.tripId ||
      !state?.costPackageId ||
      !state?.calendarId ||
      !state?.numberOfPeople
    ) {
      return;
    }

    Toast.loading('Please wait..');
    createBookingMtn.mutate();
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Booking</div>
        <div className="slider-header-title">{state.tripName}</div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSave()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <BookingForm state={state} dispatch={dispatch} submitted={hasSubmit} />
      </div>
    </div>
  );
};

export default NewBooking;
